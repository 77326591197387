import { default as indexcVjTlVre6cMeta } from "/home/archerist/meavOgrenci/pages/archive/index.vue?macro=true";
import { default as email6ocIJBuETYMeta } from "/home/archerist/meavOgrenci/pages/email.vue?macro=true";
import { default as forcepasschangemVJmfixCIXMeta } from "/home/archerist/meavOgrenci/pages/forcepasschange.vue?macro=true";
import { default as indexvmCjcUNbieMeta } from "/home/archerist/meavOgrenci/pages/index.vue?macro=true";
import { default as daterangecTeTYIzWE8Meta } from "/home/archerist/meavOgrenci/pages/reports/daterange.vue?macro=true";
import { default as indexZhDpFgsmnyMeta } from "/home/archerist/meavOgrenci/pages/reports/index.vue?macro=true";
import { default as newmembersTGUuflJrCQMeta } from "/home/archerist/meavOgrenci/pages/reports/newmembers.vue?macro=true";
import { default as ogrenciseviyetoVQzSvITUMeta } from "/home/archerist/meavOgrenci/pages/reports/ogrenciseviye.vue?macro=true";
import { default as periodicbwl2zDgBAtMeta } from "/home/archerist/meavOgrenci/pages/reports/periodic.vue?macro=true";
import { default as universitiescOwyYNiNjTMeta } from "/home/archerist/meavOgrenci/pages/reports/universities.vue?macro=true";
import { default as index85C3YRSwCNMeta } from "/home/archerist/meavOgrenci/pages/settings/index.vue?macro=true";
import { default as emails2OIAn5HY04Meta } from "/home/archerist/meavOgrenci/pages/students/[username]/emails.vue?macro=true";
import { default as familykv9tktkXRLMeta } from "/home/archerist/meavOgrenci/pages/students/[username]/family.vue?macro=true";
import { default as idpAXUN6j6XHMeta } from "/home/archerist/meavOgrenci/pages/students/[username]/id.vue?macro=true";
import { default as legaljyV4L0D6YDMeta } from "/home/archerist/meavOgrenci/pages/students/[username]/legal.vue?macro=true";
import { default as paymentsIkeiEFmRlSMeta } from "/home/archerist/meavOgrenci/pages/students/[username]/payments.vue?macro=true";
import { default as school2cDqGJLoiGMeta } from "/home/archerist/meavOgrenci/pages/students/[username]/school.vue?macro=true";
import { default as settingsNv9jLC27pOMeta } from "/home/archerist/meavOgrenci/pages/students/[username]/settings.vue?macro=true";
import { default as indexqscn0h1f4qMeta } from "/home/archerist/meavOgrenci/pages/students/index.vue?macro=true";
export default [
  {
    name: "archive",
    path: "/archive",
    component: () => import("/home/archerist/meavOgrenci/pages/archive/index.vue")
  },
  {
    name: "email",
    path: "/email",
    component: () => import("/home/archerist/meavOgrenci/pages/email.vue")
  },
  {
    name: "forcepasschange",
    path: "/forcepasschange",
    meta: forcepasschangemVJmfixCIXMeta || {},
    component: () => import("/home/archerist/meavOgrenci/pages/forcepasschange.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexvmCjcUNbieMeta || {},
    component: () => import("/home/archerist/meavOgrenci/pages/index.vue")
  },
  {
    name: "reports-daterange",
    path: "/reports/daterange",
    component: () => import("/home/archerist/meavOgrenci/pages/reports/daterange.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/home/archerist/meavOgrenci/pages/reports/index.vue")
  },
  {
    name: "reports-newmembers",
    path: "/reports/newmembers",
    component: () => import("/home/archerist/meavOgrenci/pages/reports/newmembers.vue")
  },
  {
    name: "reports-ogrenciseviye",
    path: "/reports/ogrenciseviye",
    component: () => import("/home/archerist/meavOgrenci/pages/reports/ogrenciseviye.vue")
  },
  {
    name: "reports-periodic",
    path: "/reports/periodic",
    component: () => import("/home/archerist/meavOgrenci/pages/reports/periodic.vue")
  },
  {
    name: "reports-universities",
    path: "/reports/universities",
    component: () => import("/home/archerist/meavOgrenci/pages/reports/universities.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/archerist/meavOgrenci/pages/settings/index.vue")
  },
  {
    name: "students-username-emails",
    path: "/students/:username()/emails",
    component: () => import("/home/archerist/meavOgrenci/pages/students/[username]/emails.vue")
  },
  {
    name: "students-username-family",
    path: "/students/:username()/family",
    component: () => import("/home/archerist/meavOgrenci/pages/students/[username]/family.vue")
  },
  {
    name: "students-username-id",
    path: "/students/:username()/id",
    component: () => import("/home/archerist/meavOgrenci/pages/students/[username]/id.vue")
  },
  {
    name: "students-username-legal",
    path: "/students/:username()/legal",
    component: () => import("/home/archerist/meavOgrenci/pages/students/[username]/legal.vue")
  },
  {
    name: "students-username-payments",
    path: "/students/:username()/payments",
    component: () => import("/home/archerist/meavOgrenci/pages/students/[username]/payments.vue")
  },
  {
    name: "students-username-school",
    path: "/students/:username()/school",
    component: () => import("/home/archerist/meavOgrenci/pages/students/[username]/school.vue")
  },
  {
    name: "students-username-settings",
    path: "/students/:username()/settings",
    component: () => import("/home/archerist/meavOgrenci/pages/students/[username]/settings.vue")
  },
  {
    name: "students",
    path: "/students",
    component: () => import("/home/archerist/meavOgrenci/pages/students/index.vue")
  }
]